<div *ngIf="specialOfferLabel" class="bookmark title text-white">
  <span class="bookmark-content">{{ specialOfferLabel }}</span>
</div>

<div class="offer-option" [class.unavailable]="membershipOffer.status !== membershipStatus.Available">
  <div class="offer-background d-flex flex-column align-items-center justify-content-center text-center h-100">
    <ng-container [ngSwitch]="membershipOffer.status">
      <ng-container *ngSwitchCase="membershipStatus.Available">
        <ng-container *ngIf="hasSubscribeButton; else OfferPriceTemplate">
          <a
            *ngIf="!legacy; else legacyTemplate"
            class="d-flex flex-column justify-content-center w-100 h-100"
            id="pay-{{membershipOffer.analyticsSlug}}-payOption"
            target="_blank"
            [href]="membershipOffer.url"
            (click)="clickJoinButton.emit()"
          >
            <ng-container *ngTemplateOutlet="linkContentTemplate"></ng-container>
          </a>

          <ng-template #legacyTemplate>
            <a
              class="d-flex flex-column justify-content-center w-100 h-100"
              id="pay-{{membershipOffer.analyticsSlug}}-payOption"
              [routerLink]="membershipOffer.url"
              (click)="clickJoinButton.emit()"
            >
              <ng-container *ngTemplateOutlet="linkContentTemplate"></ng-container>
            </a>
          </ng-template>

          <ng-template #linkContentTemplate>
            <ng-container *ngTemplateOutlet="OfferPriceTemplate"></ng-container>

            <div [id]="'plan-' + membershipOffer.analyticsSlug" class="title-md link text-primary mt-2">Matricule-se</div>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="membershipStatus.Unavailable">
        <div class="offer-name title font-weight-normal mb-1">
          <ng-container *ngTemplateOutlet="offerTitleTemplate"></ng-container>
        </div>

        <div class="title-md">Produto indisponível<br>Consulte outras opções</div>
      </ng-container>
    </ng-container>
  </div>
</div>


<ng-template #OfferPriceTemplate>
  <div class="offer-name title mb-2">
    <ng-container *ngTemplateOutlet="offerTitleTemplate"></ng-container>
  </div>

  <div>
    <ng-container *ngIf="membershipOffer.monthlySubscription">
      Débito no Cartão de Crédito -
    </ng-container>

    <ng-container *ngIf="membershipOffer.months > 1; else cashTemplate">
      {{ membershipOffer.months }}x de
    </ng-container>

    <ng-template #cashTemplate>Valor à vista</ng-template>
  </div>

  <div class="offer-price title mb-1">{{ 'R$ ' + membershipOffer.prices.offer.toLocaleString('pt-BR')}}</div>

  <ng-container *ngIf="!checkGymFee; else checkGymFeeTemplate">
    + {{ membershipOffer.prices.feeDescription }} de {{ membershipOffer.prices.fee | currency : 'BRL' : 'symbol' : '' : locale }}
  </ng-container>

  <ng-template #checkGymFeeTemplate>
    Consulte a taxa de adesão na unidade
  </ng-template>
</ng-template>

<ng-template #offerTitleTemplate>{{ membershipTitle }} • {{ membershipOffer.commitment }}</ng-template>
